<template>
  <div class="campus-detail__panels__container campus-detail__panels__container__3 pl-6 pr-6">
    <TextAtom
      class="text-left"
      :value="$t('campus-details.current_performance.simce_tooltip')"
      :color="'primary-dark'"
      font="inter"
      weight="400"
    />
    <div class="fill-width">
      <div class="panel_container_row panel_container_row_wrap performance mt-5 mb-5">
        <template v-for="grade in grades">
          <div :key="grade.index">
            <button
              v-t="`${grade.grade}`"
              type="button"
              class="panel_button__color panel_button__color__text"
              :class="{
                panel_button__color__activated: currentGrade === grade.index,
                panel_button__color__deactivated: currentGrade !== grade.index,
                panel_button__disabled: !hasSimce(grade.index),
                'mt-3': $vuetify.breakpoint.width < 416,
              }"
              :disabled="!hasSimce(grade.index)"
              @click="choose_grade(grade.index)"
            />
          </div>
        </template>
      </div>
    </div>
    <div class="panels__title panels__title__container text-left mb-2 mt-4">
      <TextAtom
        class="text-left"
        :value="$t('campus-details.current_performance.average_performance')"
        :color="'primary-medium'"
        font="poppins"
        weight="600"
      />
    </div>
    <section class="fill-width mt-3">
      <div class="panel_container_row panel_container_row--no-margin gap-2">
        <div
          v-for="(score, index) in ['', ...simceScores
            .filter(score => getLanguageScore(score) !== '-' || getMathsScore(score) !== '-')]"
          :key="index"
          class="panels_table_label_top"
        >
          <span v-if="index === 0"> &zwnj; </span>
          <div v-else class="panels_table_year">
            <span> {{ Object.keys(score)[0] }} </span>
          </div>
          <div
            v-for="(label, innerIndex) in getLabels"
            :key="label"
          >
            <div class="panel_container_row panels_table_label">
              <span v-if="index === 0">{{ label }}</span>
              <span v-else-if="innerIndex === 0" class="panels_table_score">{{ getMathsScore(score) }}</span>
              <span v-else-if="innerIndex === 1" class="panels_table_score">{{ getLanguageScore(score) }}</span>
              <span v-else-if="innerIndex === 2" class="panels_table_score">
                {{ getHistoryScore(score) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="false" class="panels__title panels__title__container text-left mb-2 mt-4">
      <TextAtom
        class="text-left"
        :value="$t('campus-details.current_performance.average_neighborhood')"
        :color="'primary-medium'"
        font="poppins"
        weight="600"
      />
    </div>
    <div v-if="false" class="panel_container_row mt-1">
      <img
        class="panel_container_row__abs_img ml-0"
        :src="require('@/assets/icons/face-primary-300.svg')"
        alt="face"
      />
      <span
        class="panel_container_row__white
        panel_container_row--text panel_container_row--text__salmon campus-detail__panels__container__item"
      >
        {{ getPercentile() }}
      </span>

      <span class="campus-detail__panels__performance-title--salmon text-left d-flex align-center">
        {{ $t('campus-details.current_performance.simce_neighbor_1') }}
      </span>
    </div>

    <WidgetPercent v-if="false" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import WidgetPercent from '@/components/explorer/general/campus_detail/panels/mini_panels/WidgetPercent.vue';
import TextAtom from '@/components/atoms/labels/TextAtom.vue';

export default {
  name: 'SimcePanel',
  components: {
    WidgetPercent,
    TextAtom,
  },
  data() {
    return {
      currentGrade: 6,
      simceScores: [],
      grades: [
        { index: 6, grade: '2do de Primaria' },
        { index: 8, grade: '4to de Primaria' },
        { index: 12, grade: '2do de Secundaria' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      campusDetails: 'institutions/campusDetails',
    }),
    getAvailableGrades() {
      const availableGrades = this.grades.filter((grade) => this.hasSimce(grade.index));
      return availableGrades.length > 0 ? availableGrades[0].index : 10;
    },
    getLabels() {
      return this.currentGrade === 12 ? [this.$t('campus-details.panels.math'), this.$t('campus-details.panels.language.title'), this.$t('campus-details.panels.history')] : ['Matemáticas', 'Lenguaje'];
    },
  },
  mounted() {
    this.currentGrade = this.getAvailableGrades;
    const simceSortedList = this.campusDetails.testscore_campus.reduce((acc, score) => {
      if (score.testscore_category.testscore_variable_label.id < 5) {
        if (score.year >= 2022) {
          const key = score.year.toString();
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(score);
        }
      }
      return acc;
    }, {});
    this.simceScores = Object.entries(simceSortedList).map(([key, value]) => ({ [key]: value }));
    this.currentGrade = this.grades.filter((grade) => (this.hasSimce(grade.index)))[0].index;
  },
  methods: {
    choose_grade(grade) {
      this.currentGrade = grade;
    },
    getLanguageScore(score) {
      return this.getSubjectScore(score, 1);
    },
    getMathsScore(score) {
      return this.getSubjectScore(score, 2);
    },
    getHistoryScore(score) {
      return this.getSubjectScore(score, 3);
    },
    getSubjectScore(score, subjectId) {
      const scoreArray = score[Object.keys(score)[0]];
      const filteredScores = scoreArray.filter(
        (scoreInfo) => scoreInfo.grade_label === this.currentGrade,
      );
      const subjectScore = filteredScores.find(
        (scoreInfo) => scoreInfo.testscore_category.testscore_area_label.id === subjectId,
      );
      return subjectScore !== undefined ? subjectScore.mean : '-';
    },
    getPercentile() {
      const simceScoresLength = this.simceScores.length;
      const lastSimceObj = this.simceScores[simceScoresLength - 1];
      const lastSimceArray = lastSimceObj[Object.keys(lastSimceObj)[0]];
      const mathLastSimce = lastSimceArray.find(
        (score) => score.testscore_category.testscore_area_label.id === 1,
      );
      return `${mathLastSimce.pct_mean_subd2}%`;
    },
    hasSimce(grade) {
      return this.simceScores.some((year) => year[Object.keys(year)].some((score) => score.grade_label === grade));
    },
  },
};
</script>
