<template>
  <div class="grade">
    <div class="fill-width">
      <Actions v-if="!onlyGrades" @back="$emit('backStep')" />
      <RegisterLogo v-if="!onlyGrades" inline small />
      <SmallTitle v-if="!onlyGrades" center-left :text="setTitle()" no-margin />
      <TitleInstructions v-if="!onlyGrades" :text="setInstructions()" style="margin-bottom: -12px" />
      <v-expansion-panels
        v-model="panel"
        multiple
        :style="onlyGrades ? 'margin-top: 22px' : 'margin-top: 32px'"
        class="grade--panels"
        flat
        light
      >
        <v-expansion-panel
          v-for="stage in availableStages"
          :key="stage.id"
          class="elevation-0"
          :style="buttonGrades ? 'margin-top: 16px; background-color: #ffffff' : ''"
          expand
        >
          <ExpansionPanelHeader
            :title="getLocaleProperty(stage, 'stage_name')"
            :icon-color="'#1a0c4c'"
            style="border-radius: 16px"
          />
          <v-expansion-panel-content>
            <section
              :class="{
                'd-flex flex-wrap': buttonGrades,
              }"
            >
              <div
                v-for="(grade, i) in stage.grades"
                :key="grade.id"
                class="register-main--check-div"
                style="cursor: pointer"
              >
                <div v-if="buttonGrades" style="margin-right: 16px">
                  <SelectionButton
                    :style="i !== 0 && selectionButtonColumn ? 'margin-top: 12px' : ''"
                    :text="getLocaleProperty(grade, 'grade_name')"
                    :is-active="selectedGradesId.includes(grade.id)"
                    dark
                    multi-selection-icons
                    @setValue="toggleOptions(grade, stage)"
                  />
                </div>
                <div v-else style="margin-top: 20px" class="d-flex align-center justify-start">
                  <CheckboxInput
                    style="margin-right: 8px"
                    :value="isSelected(grade)"
                    :index="grade.id"
                    :disabled-value="false"
                    :radio="inStudentFlow"
                    @setValue="toggleOptions(grade, stage)"
                  />
                  <CheckboxTitle :disabled-value="false" :text="parseGradeName(grade, stage)" />
                </div>
              </div>
            </section>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <section v-if="selectedOptions.length > 0 && !onlyGrades">
        <Subtitle
          style="margin-top: 48px"
          :light="true"
          :subtitle="'shared.grade.selected_grade'"
        />
        <section v-for="({ grade, stage }, index) in selectedOptions" :key="index">
          <GradeChip
            :stage-name="getLocaleProperty(stage, 'stage_name')"
            :grade-name="parseGradeName(grade, stage)"
            @deleteOption="toggleOptions(grade, stage)"
          />
        </section>
      </section>
    </div>
    <CallToActions
      v-if="!onlyGrades"
      :types="['main']"
      :active-value="selectedGrades.length > 0"
      :main-button-text="'user_register.continue'"
      small
      spaced
      @continue="nextStep()"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SelectionButton from '@/components/atoms/buttons/SelectionButton.vue';
import GradeChip from '@/components/atoms/chips/GradeChip.vue';
import Actions from '@/components/atoms/headers/Actions.vue';
import CheckboxInput from '@/components/atoms/inputs/CheckboxInput.vue';
import RegisterLogo from '@/components/atoms/logos/RegisterLogo.vue';
import Subtitle from '@/components/atoms/subtitles/Subtitle.vue';
import TitleInstructions from '@/components/atoms/subtitles/TitleInstructions.vue';
import CheckboxTitle from '@/components/atoms/titles/CheckboxTitle.vue';
import ExpansionPanelHeader from '@/components/atoms/titles/ExpansionPanelHeader.vue';
import SmallTitle from '@/components/atoms/titles/SmallTitle.vue';
import CallToActions from '@/components/molecules/modals/CallToActions.vue';
import { getLocaleProperty } from '@/utils/locale';

export default {
  name: 'SelectGrades',
  components: {
    Actions,
    RegisterLogo,
    SmallTitle,
    TitleInstructions,
    ExpansionPanelHeader,
    CheckboxInput,
    CheckboxTitle,
    Subtitle,
    GradeChip,
    CallToActions,
    SelectionButton,
  },
  props: {
    isInMap: {
      type: Boolean,
      default: false,
    },
    inStudentFlow: {
      type: Boolean,
      default: false,
    },
    studentToSet: {
      type: Object,
      default: null,
    },
    onlyGrades: {
      type: Boolean,
      default: false,
    },
    buttonGrades: {
      type: Boolean,
      default: false,
    },
    selectionButtonColumn: {
      type: Boolean,
      default: false,
    },
    changeStudents: {
      type: Number,
      default: 0,
    },
    selected: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      selectedOptions: [],
      panel: [],
    };
  },
  computed: {
    ...mapGetters({
      stageOptions: 'options/stages',
      grades: 'options/grades',
      activeGrades: 'authentication/activeGrades',
      isGuest: 'authentication/isGuest',
      currentStudent: 'authentication/currentStudent',
      inDigitalEnrollment: 'digitalEnrollment/inDigitalEnrollment',
      applicationCampusGrades: 'digitalEnrollment/tenantGrades',
      applicationCampusStages: 'digitalEnrollment/tenantStages',
      students: 'authentication/students',
    }),
    selectedGrades() {
      return this.selectedOptions.map(({ grade }) => grade);
    },
    selectedGradesId() {
      return this.selectedOptions.map(({ grade }) => grade.id);
    },
    availableStages() {
      if (this.inDigitalEnrollment && this.applicationCampusStages.length) {
        return this.applicationCampusStages;
      }
      return this.stageOptions;
    },
  },
  watch: {
    changeStudents: {
      handler() {
        if (
          this.currentStudent
          && Object.keys(this.currentStudent).length > 0
          && this.currentStudent.grades.length > 0
        ) {
          this.setSelectedOption(this.currentStudent.grades);
        } else {
          this.selectedOptions = [];
        }
      },
    },
    currentStudent: {
      handler(newVal) {
        if (!newVal || Object.keys(newVal).length === 0) {
          this.selectedOptions = [];
        }
      },
    },
  },
  mounted() {
    this.setSelectedOption(this.activeGrades.grades);
    if (this.inDigitalEnrollment) {
      this.panel = this.availableStages.map((_, index) => index);
    }
  },
  methods: {
    ...mapActions({
      setFastRegisterGrade: 'userRegister/setFastRegisterGrade',
      setStudentGrades: 'newStudentRegister/setStudentGrades',
      retrieveCampuses: 'institutions/retrieveCampuses',
      setApplicationGrade: 'digitalEnrollment/setApplicationGrade',
    }),
    // Refactor All to work with new grades and stages
    setSelectedOption(grades) {
      this.selectedOptions = [];
      const gradePrefill = [];
      grades.forEach((grade) => {
        const gradeFilter = this.grades.find((gradeInfo) => gradeInfo.id === grade);
        gradePrefill.push(gradeFilter);
      });
      gradePrefill
        .filter((gradeLabel) => (
          !this.inDigitalEnrollment
          || this.applicationCampusGrades.find((grade) => grade.id === gradeLabel.id)))
        .map((grade) => {
          const newGradeObj = grade;
          const selectedOption = { grade: null, stage: this.stageOptions.find((stage) => stage.id === grade.stage.id) };
          selectedOption.grade = newGradeObj;
          this.selectedOptions.push(selectedOption);
          this.$emit('setGrade', this.selectedOptions[0].grade, this.selectedOptions[0].stage);
          if (selectedOption?.stage?.id) {
            this.panel.push(selectedOption.stage.id - 1);
          }
          return newGradeObj;
        });
    },
    parseGradeName(grade, stage) {
      // This is a hardcoded hack to get the correct name of grades that have multiple names
      // The special conditions are:
      //  - (Chile) Special Stage && GradeID is in the childcare range (1-6)
      //  - (Chile & Col) Adult stage
      // For those cases, the last element in the string, split by /, otherwise the first element is used
      const splitName = getLocaleProperty(grade, 'grade_name').split('/');
      // const isSpecialAndElementary = stage.id === 4 && grade.id <= 6; // Chile-specific condition
      const isAdult = stage.id === 5; // This condition applies to Colombia and Chile
      const splitIndex = isAdult ? splitName.length - 1 : 0;
      return splitName[splitIndex].trim();
    },
    nextStep() {
      this.panel = [];
      // The format in which the grades are stored is an object with the stages ids and the grades ids as keys
      const gradeOptions = this.selectedOptions.reduce(
        (acc, option) => {
          acc.grades.push(option.grade.id);
          if (acc.stages.indexOf(option.stage.id) === -1) {
            acc.stages.push(option.stage.id);
          }
          return acc;
        },
        { stages: [], grades: [] },
      );
      if (!(this.isGuest || this.inStudentFlow)) {
        // User is not in the Student Flow -> Setting the grades for the Legal Guardian
        this.setFastRegisterGrade({ gradesData: gradeOptions }).then(() => {
          if (this.isInMap) {
            this.retrieveCampuses({
              showFilters: false,
            });
          }
          this.$emit('nextStep');
        });
      } else if (
        !this.isGuest
        && this.inStudentFlow
      ) {
        // Student Flow - Update the student grades
        const studentUUID = this.studentToSet?.uuid ?? this.currentStudent.uuid ?? this.students[0].uuid;
        const levelOptions = {
          uuid: studentUUID,
          ...gradeOptions,
        };
        this.setStudentGrades({ gradeOptions: levelOptions, updateStudent: studentUUID }).then(() => {
          if (this.isInMap) {
            this.retrieveCampuses({
              showFilters: false,
            });
          }
          if (this.inDigitalEnrollment && this.applicationCampusStages.length) {
            this.setApplicationGrade({ grade: gradeOptions.grades[0] }).then(() => {
              this.$emit('nextStep');
            });
          } else {
            this.$emit('nextStep');
          }
        });
      }
      this.$emit('sendGrade', gradeOptions);
    },
    isSelected(grade) {
      let isSelected = false;
      const gradeOption = this.selectedOptions.filter((options) => {
        if (options.grade.id === grade.id) {
          return true;
        }
        return false;
      });
      if (gradeOption.length > 0) {
        isSelected = true;
      }
      return isSelected;
    },
    toggleOptions(grade, stage) {
      if (this.inStudentFlow) {
        // If in student flow, only one grade can be selected at a time
        this.selectedOptions = [{ grade, stage }];
      } else if (!this.isSelected(grade)) {
        // If not in student flow, and grade is not already selected, add it to the list
        this.selectedOptions.push({ grade, stage });
      } else {
        // If grade is already selected, remove it from the list
        const newOptions = this.selectedOptions.filter(
          (optionSelected) => optionSelected.grade.id !== grade.id,
        );
        this.selectedOptions = newOptions;
      }
      this.$emit('setGrade', grade, stage);
      this.$emit('setGradeArray', this.selectedOptions);
    },
    setTitle() {
      if (this.inStudentFlow) {
        return 'shared.grade.student_flow_title';
      }
      return 'shared.grade.title';
    },
    setInstructions() {
      if (this.inStudentFlow) {
        return 'shared.grade.student_flow_instructions';
      }
      return 'user_register.register_role.subtitle';
    },
    getLocaleProperty,
  },
};
</script>
